export const successStories = [
	{
		name: 'YASSHMITA',
		note: "VITA STUDENT BIRMINGHAM",
		time: '02/09/2023',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-1.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'YASSHMITA JADISHKUMAR – PRO SQUASH PLAYER',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Many people want to be the best in the world at what they do – but not many actually manage to achieve it. However Yasshmita, a current resident at Vita Student, is already on her way there. At the young age of 19, she is a professional squash player representing her home-country Malaysia and is ranked in the top 100 players in the world.</p>\n' +
			'<p>Yasshmita started playing squash in her early teens and has quickly risen up the ranks. But it hasn’t come without perseverance and commitment – Yasshmita trains multiple times a day and has sacrificed a lot of her university life to succeed in her dreams.</p>\n' +
			'                    </div>',
	},
	{
		name: 'ZIHAO',
		note: "VITA STUDENT LEEDS",
		time: '27/08/2021',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-2.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'ZIHAO YANG – BRAND MANAGER AT HUAWEI',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Despite only leaving Vita Student a few years ago, Zihao is already forging a successful career in China as a Brand Manager at Huawei, China’s biggest telecommunications manufacturer.</p>\n' +
			'                    </div>'
	},
	{
		name: 'LAKECHIA',
		note: "VITA STUDENT YORK",
		time: '01/09/2018',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-3.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'LAKECHIA JEANNE – FOUNDER OF GIRLS IN SCIENCE',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>With a background in both science and media, Lakechia Jeanne has combined her experience to advance the career opportunities of women. She is the Founder of Girls in Science, a social initiative to help women into careers in STEM.</p>\n' +
			'<p>Lakechia started Girls in Science while living at Vita Student Lawrence Street in York, and since then she has helped around 3000 women into scientific careers. She hosts events, connecting graduates with employers, provides one-on-one mentorship for women, and takes part in public speaking engagements to inspire women in scientific fields.</p>\n' +
			'<p>She has won multiple awards including the Women’s Leaders Awards, which she now judges.</p>\n' +
			'                    </div>'
	},
	{
		name: 'AARON',
		note: "VITA STUDENT NEWCASTLE",
		time: '31/08/2019',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-4.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'AARON LANGFORD – SOCIAL INFLUENCER',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Aaron Langford has always strived to use his platform for change. In his daily life, he works as a Business Development Co-ordinator for Cognassist, an education-tech start-up that works to help businesses develop supportive frameworks for employees with neurodiversity.</p>\n' +
			'<p>Outside of his daily role, he is a TikTok social influencer who livestreams on a weekly basis to raise awareness of androgenic alopecia, a condition that he was diagnosed with at age 13. His livestreaming journey began after posting a viral video while living at Vita Student Westgate in Newcastle.</p>\n' +
			'<p>Gaining millions of views on his content, and up to 30,000 live viewers in a single livestream, Aaron answers questions about his alopecia and uses the power of social media to spread positivity to his audience across the world. In addition to this, he has launched multiple social and community initiatives, and is nominated for this year’s England Business Awards.</p>\n' +
			'                    </div>'
	},
	{
		name: 'KATHRYN',
		note: "VITA STUDENT LEEDS",
		time: '16/08/2023',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-5.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'KATHRYN ROBERTS – DOCUMENTARY DIRECTOR AND PODCASTER',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Kathryn didn’t expect that she would have directed her own documentary by the time that she graduated, or that she would be producing a successful podcast now into its fourth season. But here she is.</p>\n' +
			'                    </div>'
	},
	{
		name: 'MAY',
		note: "VITA STUDENT NEWCASTLE",
		time: '01/07/2017',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-6.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'MAY JAMES – BUSINESS SCALING EXPERT',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Since her time living at Vita Student studying in Newcastle, May James is now successfully forging a career on her own terms. She is a business scaling expert, coaching female CEOs across the globe and helping them to turn their small businesses into multi-million pound enterprises.</p>\n' +
			'<p>During her studies, May took a year to mentor small businesses in Uganda, but contracted a virus that later developed into a life-long illness. Knowing that she would never be able to work a typical 9-5, she was forced to remap her entire life plan.</p>\n' +
			'<p>Since then, she has firmly established herself in the world of business, gaining multiple awards for her work including the NE Young Businesswoman of the Year. She also hosts her own podcast, The Honest Business Podcast, is a mentor for Virgin StartUp, and was a Delegate for the UN Women.</p>\n' +
			'                    </div>'
	},
	{
		name: 'JUAN',
		note: "VITA STUDENT BARCELONA",
		time: '24/08/2023',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-7.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'JUAN RODRIGUEZ PABLO FALCON – CFO AT SHALION',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Many students face the difficulty of juggling their studies with their job – and for Juan, this feeling is all too familiar. As the Chief Financial Officer of Shalion, a start-up data services company in Barcelona, he has mastered the art of handling pressure and balancing time.</p>\n' +
			'<p>Juan is a Vita Student Alumni who formerly lived in our Pedralbes building in Barcelona.</p>\n' +
			'                    </div>'
	},
	{
		name: 'SIMPLE',
		note: "VITA STUDENT MANCHESTER",
		time: '31/08/2020',
		img: 'https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-2-8.webp',
		type: 'video',
		url: 'https://www.youtube.com/embed/2l5sK1YcQk0',
		contentTitle: 'SIMPLE WU – MANAGER AT BLUEFOCUS SHANGHAI',
		contentNote: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>Simple is a busy woman.</p>\n' +
			'<p>She works for BlueFocus, one of China’s leading integrated marketing and advertising agencies. As the General Manager for their entire Shanghai Brand Centre, she is responsible for around 200 employees.</p>\n' +
			'<p>Before achieving a role that many people dream of, Simple lived at Vita Student First Street, in Manchester.</p>\n' +
			'                    </div>'
	}
]