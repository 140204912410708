export const events = [
	{
		title: 'OCTOBER 3RD',
		subTitle: 'ALUMNI REUNION EVENT',
		content: "<div class=\"accordion-card__content active\" style=\"height: 181px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>An all-out reunion celebration bringing together Vita Student residents and teams from the past 10 years. With special guest speakers from global organisations, live panel Q&amp;A, live music and more.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       3RD                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>Manchester </td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'OCTOBER 11TH',
		subTitle: 'WORLD MENTAL HEALTH DAY WITH THE HAPPINESS EXPLORER',
		content: "<div class=\"accordion-card__content active\" style=\"height: 157px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>“The Happiness Explorer” Lydia Kimmerling talks to Vita Student about the key to forging a successful career underpinned by lasting happiness and fulfilment. </p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       11TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'OCTOBER 25TH',
		subTitle: 'GRADUATE APPLICATION BOOTCAMP',
		content: "<div class=\"accordion-card__content active\" style=\"height: 205px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>This year Student Circus will be bringing a series of career and job application masterclasses to Vita Student. Hone your interview skills, practice the end-to-end graduate application process, learn about visa sponsorship, secure the perfect summer internship and more.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       25TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'NOVEMBER 6TH',
		subTitle: 'TECHTIMEOUT: DIGITAL WELLBEING',
		content: "<div class=\"accordion-card__content active\" style=\"height: 181px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>Your wellbeing matters to us – physical, mental and digital. We’ve partnered with techtimeout to help our community understand how to enjoy technology both productively and healthily. Plus, everything you need to know about how to study without distraction.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       6TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'NOVEMBER 20TH',
		subTitle: 'START UP SERIES',
		content: "<div class=\"accordion-card__content active\" style=\"height: 157px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>Unleash your inner entrepreneur and get advice and support at our series of start-up talks from successful business founders.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       20TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'NOVEMBER 27TH',
		subTitle: 'HOW TO WRITE THE PERFECT CV',
		content: "<div class=\"accordion-card__content active\" style=\"height: 157px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>Work with leading CV experts to understand exactly what employers are looking for and create the perfect CV at this workshop.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       27TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>BARCELONA</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'DECEMBER 6TH',
		subTitle: 'SCHOOL OF LIFE: RESILIENCE & CALM WORKSHOP',
		content: "<div class=\"accordion-card__content active\" style=\"height: 205px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>The School of Life is a global organisation helping people lead fulfilled lives and maximise their potential through useful resources and tools. This year they’re bringing their expertise to Vita Student to help our community understand how to remain resilient and calm when navigating life’s challenges.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       6TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'January 22ND',
		subTitle: 'Nutrition Workshop with Venner Nutrition',
		content: "<div class=\"accordion-card__content active\" style=\"height: 205px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>We’ve teamed up with Venner Nutrition to help educate ourselves about how to reduce food waste, and bring fresh, healthy food to Vita Student. Stay tuned for a series of nutrition workshops and pop-up farmers markets packed with fresh ingredients to cook up a storm in your studio.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       22ND                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>All buildings</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'MARCH 7TH',
		subTitle: 'BIG TALKS',
		content: "<div class=\"accordion-card__content active\" style=\"height: 205px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>Big Talks are a series of once-in-a-lifetime events connecting the student community with the biggest names in industry and the best brands in the world. Past speakers have included Professor Brian Cox, Virgin Galactic, Adidas, Formula 1 and the BBC. Get ready for a rich line-up of Big Talks events across the UK, coming soon.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       7TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'APRIL 17TH',
		subTitle: 'STRESS AWARENESS MONTH',
		content: "<div class=\"accordion-card__content active\" style=\"height: 181px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>A series of workshops, masterclasses and relaxation events with our VIP partners to support our residents through the challenging exam period.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       17TH                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	},
	{
		title: 'MAY 1ST',
		subTitle: 'IMPROVE YOUR LINKEDIN PROFILE & START NETWORKING',
		content: "<div class=\"accordion-card__content active\" style=\"height: 181px;\">\n" +
			"                                    <div class=\"accordion-card__content-inner wp-block-post-content\">\n" +
			"                                        <p>A workshop for anyone wanting to build their network and leverage the power of LinkedIn, including one-on-one guidance on creating a LinkedIn profile to impress.</p>\n" +
			"                                    </div>\n" +
			"                                                                            <table class=\"accordion-card__event-information\">\n" +
			"                                                                                            <tbody><tr>\n" +
			"                                                    <td>Date</td>\n" +
			"                                                    <td>\n" +
			"                                                       1ST                                                    </td>\n" +
			"                                                </tr>\n" +
			"                                                                                                                                        <tr>\n" +
			"                                                    <td>Location</td>\n" +
			"                                                    <td>ALL BUILDINGS</td>\n" +
			"                                                </tr>\n" +
			"                                                                                    </tbody></table>\n" +
			"                                                                    </div>"
	}
]