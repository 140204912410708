<template>
    <div class="container-fluid p-0">
        <section class="vita-search">
            <div class="container p-l-50 p-r-50 pt-0 pb-0 mt-0 mb-0">
                <h1 class="title-line">BEYOND AMBITION.</h1>
                <div class="title-note m-t-5">
                    欢迎来到VITA STUDENT第十年。里程碑之年，我们以10项改变人生命运的竞赛与奖项、鼓舞人心的VITA校友故事和Begin Big系列活动，诚邀您共同见证荣誉十年！
                </div>
            </div>
        </section>

        <section class="vita-video-block">
            <video class="pt-0 pb-0" title="VS Homepage" preload="none" loop width="100%" height="100%"
                   frameborder="0" muted playsinline autoplay>
                <source class="cursor" src="https://static.vitaalumnicn.com/static/vsweb/beyond-ambition/header_beyond-ambition-1080p.mp4"
                        type="video/mp4">
            </video>
        </section>

        <section class="title-bg">
            <div class="title-bg-img-1 title-bg-img"></div>
        </section>

        <section v-show="false" class="vita-cities row">
            <div class="city-left col-3">
                <div class="left-content">
                    <div class="title-line">
                        成功故事
                    </div>
                    <div class="title-note mt-1">
                        我们的住户是如何实现职业理想的？
                    </div>
                </div>
            </div>
            <div class="col-9 p-l-45">
                <div class="city-right">
                    <div class="scroll-container custom-scroll-2 p-b-36" ref="scrollContainer1">
                        <div class="item cursor"
                             @click=""
                             v-for="(item, index) of successStories"
                             :key="index">
                            <div class="item-title">
                                <span>{{ item.name }}</span>
                            </div>
                            <img decoding="async" loading="lazy" :src="item.img">
                        </div>
                        <div class="left-right-btn">
                            <div class="btn-arrow cursor icon iconfont" @click="handleOffset('left', 1)"> &#xe64c;</div>
                            <div class="btn-arrow cursor icon iconfont" @click="handleOffset('right', 1)"> &#xe64d;</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <competition @handleRightDialog="handleRightDialog"></competition>
        <events v-show="false"></events>

        <section class="section-black vita-hero-banner d-flex justify-content-center align-items-center">
            <div class="content position-relative">
                <div class="position-absolute panel-1"></div>
                <div class="position-absolute panel-2">
                    <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-7.webp"
                         alt="">
                </div>
                <div class="position-absolute panel-3 text-white" style="height: 220px">
                    <div class="title font-size-55 font-weight-700 text-trade" style="line-height: 1.2">
                        VITA全英校友会
                    </div>
                    <div class="font-size-12 font-weight-700">
                        首个专业官方打造高端英国海归社群品牌，连接高品质留英社交圈与优质品牌企业。校友会成员为往应届VITA STUDENT英国高端体验式学生公寓住户，覆盖全英18座城市的QSTOP100高校。提供职业发展、社交生活、大牌折扣等多重福利。
                    </div>
                </div>
                <div class="position-absolute panel-4 cursor"  @click="handelDialog(1)">
                    <div class="view-all-cities text-uppercase w-auto p-l-10 p-r-10 text-trade font-weight-900">
                        加入VITA全英校友会
                    </div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>

                <el-dialog :show-title="false" :visible.sync="dialogVisibleImg">
                    <div class="dialog-body text-center">
                        <img width="260" height="260" :src="imgObj.url" alt="">
                    </div>
                    <div slot="footer" class="dialog-footer"></div>
                </el-dialog>
            </div>
        </section>

        <section class="vita-static-gallery container-fluid">
            <div class="row">
                <div class="left-panel col-4" style="padding-top: 0">
                    <div class="panel-head sticky-top-0" style="display: block;padding-top: 30px">
                        <div class="content-title">
                            我们的宗旨
                        </div>

                        <div class="left-content">
                            维塔学舍是为那些想要拥有一切的学生准备的。为想要无拘无束生活的学生而设
                            <br><br>
                            维塔学舍提供全方位的服务，为您减轻大学生活的压力，让您专注于学习和生活。
                            <br><br>
                            我们的宿舍楼里充满了来自不同地方的友好面孔。大家都在这里充分利用自己的大学时光。无论您是创意型人才还是学术型人才，维塔学舍都是您学习、成长和建立联系的好地方。
                        </div>
                    </div>
                </div>
                <div class="col-panel col-4 p-0">
                    <div class="img-card sticky-top-0">
                        <div class="card-footer">
                            <div class="note">
                                TIME MANAGEMENT TIPS FOR STUDENTS
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img img-center" decoding="async" loading="lazy"
                             src="https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-5-1.webp">
                    </div>
                </div>
                <div class="col-panel col-4 p-0">
                    <div class="img-card">
                        <div class="card-footer">
                            <div class="note">
                                FREE CAREER RESOURCES FOR STUDENTS IN THE UK
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img-right" decoding="async" loading="lazy"
                             src="https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-5-2.webp">
                    </div>

                    <div class="img-card">
                        <div class="card-footer">
                            <div class="note">
                                HOW TO APPLY FOR GRADUATE SCHEMES
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img-right" decoding="async" loading="lazy"
                             src="https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-5-3.webp">
                    </div>

                    <div class="img-card">
                        <div class="card-footer">
                            <div class="note">
                                VITA STUDENT ALUMNI: 10-YEAR REUNION EVENT
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img-right" decoding="async" loading="lazy"
                             src="https://static.vitaalumnicn.com/static/vsweb/beyond-ambition-5-4.webp">
                    </div>
                </div>
            </div>
        </section>

        <beyond-ambition-social-stories class="p-t-30"></beyond-ambition-social-stories>

        <section class="title-bg bg-red">
            <div class="title-bg-img-3 title-bg-img"></div>
        </section>

        <section class="w-100">
            <img class="footer-img" decoding="async" loading="lazy"
                 src="https://static.vitaalumnicn.com/static/vsweb/Beyond-Ambition-footer-banner.webp">
        </section>

        <div class="right-dialog">
            <el-dialog :show-title="false" class="right-dialog" :visible.sync="rightDialog">
                <div class="dialog-content" v-if="rightItem">
                    <div class="btn-close el-icon-close" @click="rightDialog=false"></div>
                    <div class="dialog-header font-size-70 font-weight-900 text-trade text-uppercase">
                        {{ rightItem.title }}
                    </div>
                    <div class="sub-title text-uppercase text-trade">{{rightItem.subTitle}}</div>
                    <div class="dialog-body">
                        <img :src="rightItem.url" alt="">

                        <div class="competition text-trade">
                            活动形式：{{ rightItem.way || '竞赛' }}
                        </div>
                        <div class="text-white" v-html="rightItem.content"></div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>

import {getCitiesAPI, getKeywordsAPI} from '@/apis/common'
import beyondAmbitionSocialStories from './components/beyond-ambition-social-stories'
import {successStories} from "@/assets/data/successStories";
import events from './components/events.vue'
import competition from './components/competition.vue'
export default {
    name: "home",
    components: {
        beyondAmbitionSocialStories,
        events,
        competition
    },
    data() {
        return {
            dialogVisibleImg: false,
            bgType: 1,
            endDate: new Date("2023-11-06T08:00:00").getTime(),
            keyword: null,
            keywords: [],
            cities: [],
            isFocus: false,
            rightDialog: false,
            rightItem: null
        }
    },
    created() {
        this.getCities()
        this.getKeywords()
    },
    computed: {
        successStories: function (){
            return successStories
        },
        imgObj() {
            return {
                title: this.$t('home.wechat'),
                url: require('assets/images/icon/vita_app_qrcode.png')
            };
        }
    },
    methods: {
        handelDialog() {
            this.dialogVisibleImg = true
        },
        handleRightDialog(item) {
            this.rightDialog = true
            this.rightItem = item
        },
        getKeywords() {
            getKeywordsAPI().then(res => {
                this.keywords = res.data.data
            })
        },
        handleHref() {
            //window.open('https://www.vitastudent.cn/campaign/new-2022-10', '_blank');
        },
        handleBlur() {
            setTimeout(() => this.isFocus = false, 120)
        },
        async getCities() {
            if (this.$store.state.cities.length > 0) {
                this.cities = this.$store.state.cities;
            } else {
                await getCitiesAPI({showApartments: 1}).then(res => {
                    if (res.status === 200) {
                        this.cities = res.data.data
                        this.$store.commit('setCities', this.cities)
                    }
                })
            }
        },
        handleOffset(offset, type = null) {
            let scrollContainer = this.$refs['scrollContainer' + type];
            if (offset === 'left') {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft - 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            } else {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft + 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "./index.less";

.right-dialog {

    /deep/ .el-dialog{
        overflow: hidden !important;
        padding: 70px 40px 60px 70px !important;
        background: #e91a4c;
        width: 50% !important;
        min-width: 700px !important;
        max-width: 900px !important;
        height: 100vh !important;
        margin: 0 0 0 auto !important;
        line-height: 1;

        .el-dialog__body {
            padding: 0;
            height: 100%;
            overflow-x: hidden !important;
            overflow-y: auto !important;
        }

        .el-dialog__body::-webkit-scrollbar {
            width: 0.1rem;
            height: 0.1rem;
            background-color: rgba(212, 212, 212, 0);
        }

        /*定义滚动条轨道 内阴影+圆角*/
        .el-dialog__body::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 1px #e91a4c;
            border-radius: 1px;
            background-color:#e91a4c;
        }

        /*定义滑块 内阴影+圆角*/
        .el-dialog__body::-webkit-scrollbar-thumb {
            border-radius: 1px;
            -webkit-box-shadow: inset 0 0 1px #fff;
            background-color: #fff;
        }

        .el-dialog__body::-webkit-scrollbar {
            width: 0.1em;
        }

        .el-dialog__body::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 0 rgb(243, 243, 243);
        }

        .el-dialog__header {
            padding: 0 !important;
            display: none !important;
        }

        .dialog-content {
            padding-right: 30px;

            .btn-close {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                color: #fff;
                font-size: 28px;
                font-weight: 900;
                background: #000;
                border-radius: 0;
            }

            .dialog-header {
                color: #fff;
                -webkit-font-smoothing: antialiased;
                width: 560px;
                word-break: normal;
                overflow-wrap: break-word;
            }

            .sub-title {
                color: #000;
                margin-top: 8px;
                margin-bottom: 15px;
                font-size: 26px;
                font-weight: 900;
            }

            .dialog-body {
                img {
                    padding: 20px 0 35px;
                    width: 100%;
                }

                .competition {
                    color: #fff;
                    margin: 20px 0 15px;
                    font-size: 26px;
                    position: relative;
                }

                .competition:before {
                    content: "";
                    display: block;
                    width: 100px;
                    background-color: #000;
                    position: absolute;
                    height: 4px;
                    top: -20px;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

    }
}


</style>
