<template>
    <div>
        <section class="events">
            <div class="events-head">
                <div class="events-title">EVENTS</div>
                <div class="events-subTitle">
                    Attend events and activities to set you on the path to success.
                </div>
            </div>
            <div class="accordion-group">
                <div class="accordion-card-head" :class="index === 0 ? 'border-top' : ''" v-for="(item, index) of events">
                    <div class="accordion-header cursor" @click="handleSpan(index)">
                        <div class="accordion-rn">{{ item.title }}</div>
                        <div class="accordion-question">
                            <span class="sub-title">{{ item.subTitle }}</span>
                        </div>
                        <div class="accordion-more">
                            <span v-if="eventsSpans[index]">LESS -</span>
                            <span v-else>MORE +</span>
                        </div>
                    </div>
                    <div class="accordion-content" v-if="eventsSpans[index]" v-html="item.content"></div>
                    <div class="underline"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {events} from "@/assets/data/events";

export default {
    name: 'events',
    data() {
        return {
            eventsSpans: [],
            keyword: null,
            keywordSearch: null
        }
    },
    computed: {
        events() {
            return events
        }
    },
    methods: {
        handleSpan(index) {
            this.eventsSpans[index] = !this.eventsSpans[index];
            this.$forceUpdate();
        },
    },
}
</script>

<style scoped>

.events {
    padding: 50px;

    .events-head {
        color: #fff;

        .events-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            display: inline-block;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
        }

        .events-title:before {
            width: 100%;
            content: "";
            display: inline-block;
            background-color: #e91a4c;
            height: 7px;
        }

        .events-subTitle {
            font-size: 16px;
        }
    }

    .accordion-group {
        margin-top: 30px;
        color: #fff;

        .sub-title {
            font-size: 26px;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
        }

        .border_top {
            border-top: 1px rgba(255, 255, 255, 0.5) solid;
        }

        .accordion-card-head {
            position: relative;
            font-size: 16px;
            padding: 10px 0;
            border-bottom: 1px rgba(255, 255, 255, 0.5) solid;

            .accordion-header {
                display: flex;
                font-size: 16px;

                .accordion-rn {
                    width: 160px;
                    font-size: 14px;
                    white-space: nowrap;
                    word-break: break-word;
                }

                .accordion-question {
                    margin-right: auto;
                }

                .accordion-more {

                }

            }

        }

        ::v-deep .accordion-card__content {
            padding-top: 27px !important;
            padding-bottom: 11px !important;
            padding-left: 80px !important;
        }

        ::v-deep table.accordion-card__event-information tr td:first-of-type {
            padding-right: 2.8125rem !important;
        }

        ::v-deep table.accordion-card__event-information tr td {
            text-transform: uppercase !important;
            vertical-align: top !important;
            font-size: 1rem !important;
        }

        .accordion-card-head:hover {

            .underline {
                position: absolute;
                bottom: 0;
                height: 1px;
                background-color: #999999;
                animation-name: animationUnderLine;
                animation-duration: .5s;
                animation-fill-mode: forwards;
                width: 0;
            }
        }


        @keyframes animationUnderLine {
            0% {
                width: 0;
            }
            100% {
                width: 100%;
            }
        }
    }

}

.swiper {
    position: relative;
    width: 100%;
    margin: 20px auto;
    height: 400px;
    overflow: hidden;
    border: 1px solid #ccc;
    box-sizing: border-box;
    user-select: none;
}

.swiperBody {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    white-space: nowrap;
    min-width: 100%;
}

.swiperBody .sitem {
    display: inline-block;
    height: 100%;
    background-position: center center;
    background-origin: padding-box;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.pointer {
    height: 6px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 2;
    text-align: center;
    font-size: 0;
}

.pointer .span {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
}

.pointer .span.active {
    background-color: rgb(248, 123, 7);
}
</style>