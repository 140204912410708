export const competitions = [
	{
		title: '电子产品十件套全家桶',
		subTitle: '已开奖',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-1.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>想推出自己的品牌或业务？赢取价值 3000 英镑的电子产品十件套全家桶，获得成功所需的一切。</p>\n' +
			'<p>奖品包含：<br>\n' +
			'Macbook Pro 13<br>\n' +
			'AirPod 3<br>\n' +
			'Apple Magic Mouse<br>\n' +
			'AirTag<br>\n' +
			'Amazon Kindle Scribe<br>\n' +
			'Amazon Echo Show 5<br>\n' +
			'GoPro Hero 12<br>\n' +
			'GoPro Tripod Bundle Travel Kit<br>\n' +
			'RØDE NT-USB Microphone<br>\n' +
			'Stubble &amp; Co Kit Backpack</p>\n' +
			'<p>要参加抽奖，符合条件的参与者必须在Instagram上关注VitaStudent主页，点赞置顶帖子，并在评论区标记一位朋友。</p>\n' +
			'<p>只有维塔学舍的现住户，或已经预订了24/25学年的住宿，才有资格参加抽奖。</p>\n' +
			'<p>报名开始日期为11月2日中午12点（英国时间），于2023年11月16日晚上23:59结束。</p>\n' +
			'<p><a style="color: #000000" href="https://www.vitastudent.com/en/beyond-ambition-competition-terms-and-conditions/" target="”_blank”">请阅读比赛条款和条件。</a> </p>\n' +
			'                    </div>'
	},
	{
		title: '免全年VITA房租',
		subTitle: '已开奖',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-2.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>作为英国高端体验式学生公寓，VITA STUDENT已在全球千万留英住户的见证与支持下，迈入了第十个年头。在此十周年之际，为感谢全球各届住户的大力支持，我们为大家特意献上十周年特别企划——</p>\n' +
			'<p>VITA十周年特别企划的第5场，我们为您送上VITA利物浦学生公寓全年房租减免，简直太酷啦！</p>\n' +
			'<p>报名方式：<br />1.关注官方IG @vitastudent<br />2.点赞本场活动原帖<br />3.评论区留言你的答案：在你心中头等重要的是什么？<br />获奖者将于评论区抽取哦！</p>\n' +
			'<p>活动时间：<br />即日起至2024年3月21日 <br />获奖者将于3月30日公布</p>\n' +
			'<p>参与条件：<br />已预定VITA STUDENT任一城市学生公寓24/25学年的同学。</p>\n' +
			'                    </div>'
	},
	{
		title: '免费参加国际会议',
		subTitle: '已开奖',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-3.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'<p>本月的奖品是一张通向全球顶尖会议的门票（自选），让您有机会与梦想行业的领导者进行交流和学习，并在此过程中前往一个新的国家。</p>\n' +
			'<br><p>想在科技领域工作？这个奖项可以让您参加在荷兰阿姆斯特丹举行的人工智能世界峰会，在那里您将与AI领域的资深大佬同框。</p>\n' +
			'<br><p>有改变世界的雄心壮志？在加拿大蒙特利尔举行的"One World"峰会上向未来领袖学习。</p>\n' +
			'<br><p>梦想成为下一个美妆大亨？如果是这样，这个比赛对您来说再合适不过了——因为您有可能赢得美国洛杉矶世界著名的BeautyCon门票。</p>' +
			'<p><br>参赛方式：<br>提交参赛作品，告诉我们您想参加的会议及其原因。可以是文字形式（最多500字）、视频（最多3分钟）或PDF作品。</p>' +
			'<br><p>请于<a href="https://linktr.ee/vita_student">https://linktr.ee/vita_student</a> 提交您的参赛作品链接，可以保存在Google Docs、Dropbox、Youtube等地。请确保您创建了分享链接，以便我们可以看到您的作品。</p>' +
			'<br><p>随后，我们的团队将与一名独立评委一起审查所有参赛作品，并选出获奖者。</p>' +
			'<br><p>比赛于 1 月 29 日截止。</p>' +
			'</div>'
	},
	{
		title: '环游世界旅行基金',
		subTitle: '即将开启',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-4.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>我们想要带您和您的一位好友体验一次千载难逢的免费旅行——游览从未见过的风景，感受从未到过的地方，游遍多个国家和城市。</p>\n' +
			'<p>竞赛报名日期及详情即将公布。</p>\n' +
			'                    </div>'
	},
	{
		title: '精装您的VITA房间',
		subTitle: '即将开启',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-5.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>我们非常欢迎住户精心装扮自己的VITA房间，但这个月，我们想给您一个机会，把您的房间打造成一切理想中的样子。</p>\n' +
			'<p>我们免费提供专业室内设计团队，以一对一合作的经验和预算，为您打造“梦中情房”。</p>\n' +
			'<p>竞赛报名日期及详情即将公布。</p>\n' +
			'                    </div>'
	},
	{
		title: '比斯特VIP购物日',
		subTitle: '即将开启',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-6.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>享受比斯特购物村的奢华私人购物体验，更新你的高端服饰储备，助力出彩求职面试！</p>\n' +
			'<p>中奖用户将获得我们提供的VIP明星购物体验，最高可消费 2000 英镑，免费使用独家私人购物休息室、品香槟、在Cecconi\'s餐厅享用VITA请客免费午餐。</p>\n' +
			'<p>竞赛报名日期及详情即将公布。</p>\n' +
			'                    </div>'
	},
	{
		title: '参与欧美一线歌手MV拍摄',
		subTitle: '即将开启',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-7.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>与英国顶级音乐导演KC Locke面对面体验欧美一线歌手MV拍摄。</p>\n' +
			'<p>曾为Stormzy、Calvin Harris、Little Mix和Aitch等音乐人创作获奖音乐视频的KC与VITA STUDENT合作，将为一位充满热情的获奖者提供机会，体验为世界上最优秀的音乐艺人拍摄音乐视频的工作！</p>\n' +
			'<p>竞赛报名日期及详情即将公布。</p>\n' +
			'                    </div>'
	},
	{
		title: '悦心疗愈私人课程',
		subTitle: '立即参加',
		way: '抽奖',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-8.webp',
		content: '<div class="vita-slide-out-modal__content">' +
			'<p>英国高端体验式学生公寓VITA STUDENT已在全球千万留英住户的见证与支持下，迈入了第十个年头！在此十周年之际，为感谢全球各届住户的大力支持，我们为大家特意献上十周年特别企划系列第6场——</p>' +
			'<p>我们非常理解，对于留英同学来说，学习是头等重要的大事。在努力学习之余，照顾好自己的身心健康同样重要。因此，我们送上本月VITA十周年特别企划大奖：7日葡萄牙包机酒食宿悦心疗愈私人旅程！</p>' +
			'<p>在拥有热水浴缸、泳池和桑拿浴室的别墅里，沐浴葡萄牙灿烂阳光，滋养身心，好好放松。机票、酒店、餐食费用全部包含。</p>' +
			'<p>不仅如此，更有多重好礼相送：' +
			'<br>-苹果AirPods Pro（2代）' +
			'<br>-Stanley Quencher H2.0 保温杯（1.2L）' +
			'<br>-NEOM香薰精油机&精油套装' +
			'<br>-The White Company精美睡袍</p>' +
			'<p>活动详情及参与方法如下：' +
			'<br>地点：葡萄牙阿尔加维' +
			'<br>旅程时间：2024年8月19-25日</p>' +
			'<p>报名方式' +
			'<br>1. 关注官方IG @vitastudent' +
			'<br>2. 点赞本场活动原帖' +
			'<br>3. 评论区留言你的答案：你最喜欢的疗愈放松活动是什么？</p>' +
			'<p>获奖者将于评论区抽取哦！</p>' +
			'<p>活动时间' +
			'<br>即日起至2024年4月18日英国时间23:59' +
			'<br>获奖者将于4月30日公布</p>' +
			'<p>参与条件' +
			'<br>VITA STUDENT 23/24学年英国与西班牙现住户，及已预定VITA' +
			'<br>STUDENT任一城市24/25学年学生公寓的同学。</p>' +
			'<p>更多适用条款，请访问VITA官网Beyond Ambition页面。</p>' +
			'<p>VITA十周年，与您共创无限可能。</p>' +
			'</div>'
	},
	{
		title: '免费进修任意技能培训',
		subTitle: '即将开启',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-9.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>您是否一直以来想要学习一项技能，但需要资金来完成一门特定的课程？让我们来探讨一下吧。</p>\n' +
			'<p>您可以通过这场活动告诉我们你想要学习的课程，我们将为中奖者提供最高2000英镑的奖金。</p>\n' +
			'<p>无论是编程、摄影、时装设计、语言，还是商业管理，我们都希望能够帮助您拓展知识面，助您走上成功之路。</p>\n' +
			'<p>竞赛报名日期及详情即将公布。</p>\n' +
			'                    </div>'
	},
	{
		title: '海蓝之谜&莱珀妮高端护肤礼遇',
		subTitle: '已开奖',
		url: 'https://static.vitaalumnicn.com/static/vsweb/competition-10.webp',
		content: '<div class="vita-slide-out-modal__content">\n' +
			'                        <p>VITA十周年特别企划的第二场，正值圣诞季，我们为大家送上价值3000英镑（折合RMB2w+）的全套高端护肤豪华礼遇，迎接 2024年的到来。</p>\n' +
			'<p>奖品包含：<br>\n' +
			'价值2000英镑的LA MER海蓝之谜护肤套装<br>\n' +
			'La Prairie莱珀妮豪华面部护理（Harvey Nichols Knightsbridge店），并享受附送的精致下午茶<br>\n' +
			'价值500英镑的Harvey Nichols Knightsbridge店购物券<br></p>\n' +
			
			'<p>自2013年第一栋VITA STUDENT开业以来，有多少学生住户在VITA任一城市居住过6个月及以上？<br>\n' +
			'A. 10,037<br>\n' +
			'B. 26,984<br>\n' +
			'C. 39,258<br>\n' +
			'D. 46,606<br>\n' +
			'E. 52,439<br>\n' +
			'选择一个答案，在IG评论区留下你的答案，每位回答正确的合格参赛者都将参加抽奖，随机抽取一名获奖者。您只能发表一条评论，提交多个答案者将被取消资格。<br>\n' +

			'<p>只有维塔学舍的现住户，或已经预订了24/25学年的住宿，才有资格参加抽奖。</p>\n' +
			'<p>前往伦敦Harvey Nichols Knightsbridge的路费需自理。活动将于 12 月 14 日结束，获奖者名单将在圣诞节前公布。</p>\n' +
			'<p><a style="color: #000000" href="https://www.vitastudent.com/en/beyond-ambition-competition-terms-and-conditions/" target="”_blank”">请阅读比赛条款和条件。</a> </p>\n' +
			'                    </div>'
	}
]