<template>
    <div>
        <section class="competitions">
            <div class="competition-head">
                <div class="competition-title">竞赛活动</div>
                <div class="competition-subTitle">
                    至2024年8月，每月将揭晓全新竞赛活动，赢取10项改写人生的重磅大奖。
                </div>
            </div>
        </section>


        <section class="img-wall-section">
            <div :style="{backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('+currentImage+')'}" class="row wall-bg" >
                <div class="col-3 p-0">
                    <div class="img-wall d-flex justify-content-center align-items-center">
                        <img class="w-75" src="https://www.vitastudent.com/wp-content/uploads/2023/09/10-year-svg.svg" alt="">
                    </div>
                </div>
                <div class="col-3 p-0 d-flex justify-content-center align-items-center" v-for="(item, ind) of competitions" :key="ind">
                    <div class="img-wall cursor"
                         @click="handlePanel(item)"
                         :class="[2, 6].includes(ind) ? 'border-end-0' : [7, 8, 9].includes(ind) ? 'border-bottom-0' : ''"
                         @mousemove="toggleHover(item.url)">
                        <div class="wall-title">{{ item.title }}</div>
                        <div class="ms-auto me-auto d-flex align-items-center wall-link">
                            <div class="link-area">
                                <div class="link-text">{{ item.subTitle }}</div>
                                <div class="go-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 p-0">
                    <div class="img-wall last-img-wall cursor border-bottom-0 border-end-0 d-flex justify-content-center align-items-center font-size-30 text-trade text-uppercase">
                        Terms & Conditions
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import {competitions} from "@/assets/data/competitions";

export default {
    name: 'competition',
    data() {
        return {
            currentImage: 'https://static.vitaalumnicn.com/static/vsweb/competition-1.webp',
            eventsSpans: [],
            keyword: null,
            keywordSearch: null
        }
    },
    computed: {
        competitions() {
            return competitions
        }
    },
    methods: {
        toggleHover(url) {
            if (this.currentImage !== url) {
                this.currentImage = url;
                this.$forceUpdate();
            }
        },
        handlePanel(item) {
            this.$emit('handleRightDialog', item)
        }
    },
}
</script>

<style lang="less" scoped>

.competitions {
    padding: 50px;

    .competition-head {
        color: #fff;

        .competition-title {
            font-size: 26px;
            font-weight: 700;
            line-height: 1.2;
            display: inline-block;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
        }

        .competition-title:before {
            width: 100%;
            content: "";
            display: inline-block;
            background-color: #e91a4c;
            height: 7px;
        }

        .competition-subTitle {
            font-size: 16px;
        }
    }

}

.img-wall-section {
    background-color: #000;
    margin: 0 45px;
    position: relative;


    .row {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .wall-bg {
        background-size: 105%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .img-wall {
        position: relative;
        aspect-ratio: 355 / 259;
        color: #fff;
        border-right: 1px rgba(255, 255, 255, 0.5) solid;
        border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
        width: 100%;

        .wall-title {
            max-width: 240px;
            width: 100%;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 30px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .3s ease;
            position: absolute;
            top: 80px;
            left: 50%;
            right: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            line-height: 1.2;
        }

        .wall-link {
            font-family: Trade Gothic LT Std Condensed, sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 16px;
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .link-area {
                align-items: center;
                display: inline-flex;
                white-space: nowrap;
                position: relative;

                .link-text {
                    background-color: rgba(0, 0, 0, 0);
                    border: 1px solid #d1d1d1;
                    border-right: 0;
                    line-height: 36px;
                    height: 40px;
                    text-transform: uppercase;
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                    font-size: 16px;
                    padding: 4px 15px 0 15px;
                    font-weight: bold;
                }

                .go-btn {
                    font-size: 22px;
                    background-color: #e91a4c;
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                }

            }
        }

    }

    .img-wall:hover {
        background-color: #e91a4c;

        .go-btn {
            background-color: #fff !important;
            color: #e91a4c !important;
        }
    }

    .last-img-wall {
        text-decoration: none !important;
    }

    .last-img-wall:hover {
        text-decoration: underline !important;
    }

}

</style>